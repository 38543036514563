import Link from "next/link";
import type { MenuItem } from "~/components/layout/OverviewHeader";
import { useTargetSearch } from "~/providers/TargetSearchProvider";

export default function HeaderMenu({
  items,
  activeTabState,
}: Readonly<{
  items: MenuItem[];
  activeTabState: [string, (value: string) => void];
}>) {
  const [activeTab, setActiveTab] = activeTabState;
  const { dispatch } = useTargetSearch();

  const handleTabSwitch = (item: { name: string }) => {
    const { name } = item;
    dispatch({
      type: "REMOVE_ALL_TARGETS",
    });
    setActiveTab(name);
  };
  return (
    <ul className="flex items-end w-full gap-4 border-divider font-medium h-10">
      {items.map((item) => {
        const isActive = activeTab === item.name;
        if (!item.href)
          return (
            <li
              key={item.name}
              className={`border-b-2 px-2 transition-colors ${
                isActive
                  ? "border-current text-offblack"
                  : "border-transparent text-muted hover:border-gray-200"
              }`}
            >
              <button
                className="flex content-center outline-none items-center gap-2 pb-2 whitespace-pre"
                onClick={() => handleTabSwitch(item)}
              >
                <item.icon className="sm-icon" />
                {item.name}
              </button>
            </li>
          );

        return (
          <Link href={item.href} key={item.name}>
            <li
              className={`flex content-center items-center  gap-2 border-b-2 pb-1 ${
                !isActive
                  ? "border-transparent text-muted"
                  : "border-current text-offblack"
              }`}
            >
              <item.icon className="sm-icon" />
              {item.name}
            </li>
          </Link>
        );
      })}
    </ul>
  );
}
