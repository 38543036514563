export function getEightMonthsAgo(date: Date) {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() - 8);
  return newDate;
}

export const getLocalDateString = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const monthString = month < 10 ? `0${month}` : month;
  const day = date.getUTCDate();
  const dayString = day < 10 ? `0${day}` : day;
  return `${year}-${monthString}-${dayString}`;
};
// format date in format Friday 1st January 2021
export const getLocalDateStringLong = (date: Date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const validateDateFormat = (date: string) => {
  if (!date) return true;
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const test = dateRegex.test(date);
  if (!test) {
    return false;
  }
  const testDate = new Date(date);
  if (testDate.toString() === "Invalid Date") {
    return false;
  }
  return true;
};
