export const ADD_ORG_TARGET = "ADD_ORG_TARGET";
export const ADD_USER_TARGET = "ADD_USER_TARGET";
export const ADD_REPO_TARGET = "ADD_REPO_TARGET";
export const REMOVE_TARGET = "REMOVE_TARGET";
export const SET_REMOVED_TARGETS = "SET_REMOVED_TARGETS";
export const SET_SEARCHING = "SET_SEARCHING";
export const SET_SHOW_REPOS = "SET_SHOW_REPOS";
export const SET_SHOW_USERS = "SET_SHOW_USERS";
export const SET_SHOW_ORGS = "SET_SHOW_ORGS";
export const SET_SHOW_EMPTY = "SET_SHOW_EMPTY";
export const SET_ACTIVE_TARGETS = "SET_ACTIVE_TARGETS";
export const SET_HAS_TOO_MANY_TARGETS = "SET_HAS_TOO_MANY_TARGETS";
export const REMOVE_ALL_TARGETS = "REMOVE_ALL_TARGETS";
export const SET_FULL_ORG = "SET_FULL_ORG";
