import { createContext, useContext, type ReactNode } from "react";
import type { Tracker } from "@openqlabs/drm-db";

const trackerContext = createContext<Tracker | null>(null);

const trackerNameContext = createContext<string | null>("");

export function useTracker() {
  return useContext(trackerContext);
}

export function useTrackerName() {
  return useContext(trackerNameContext);
}

export function TrackerProvider({
  initialTracker,
  children,
}: {
  children: ReactNode | ReactNode[];
  initialTracker?: Tracker | null;
}) {
  const trackerName = initialTracker?.trackerName;
  return (
    <>
      <trackerContext.Provider value={initialTracker ?? null}>
        <trackerNameContext.Provider value={trackerName ?? null}>
          {children}
        </trackerNameContext.Provider>
      </trackerContext.Provider>
    </>
  );
}
