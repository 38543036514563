import { createContext } from "react";
import InitialState from "./TargetSearchInitialState";

import type {
  ActionOptionsWithPayload,
  TargetSearchContextComplete,
} from "./TargetSearchContextType";
const ComposerContext = createContext<TargetSearchContextComplete>({
  state: InitialState,
  dispatch: () => ({}) as ActionOptionsWithPayload,
});

export default ComposerContext;
