import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { type Campaign as CompleteCampaign } from "~/server/api/model/campaign";
import { api, type RouterOutputs } from "~/utils/api";
import { useTeamAccount } from "./TeamAccountProvider";
import { useCampaigns } from "./CampaignsProvider";

type RefetchFunc = () => Promise<void>;
type TargetDisconnector = (
  githubTargetId: string,
  targetContactId: string,
  type: "user" | "repo"
) => void;

type Campaign = RouterOutputs["campaign"]["campaignById"];

const CampaignContext = createContext<Campaign | null>(null);
const CampaignRefetchContext = createContext<RefetchFunc | null>(null);
const CampaignUpdateContext = createContext<ReturnType<
  typeof api.campaign.campaignUpdate.useMutation
> | null>(null);

const CampaignDisconnectTargetContext =
  createContext<TargetDisconnector | null>(null);

export function useCampaign() {
  const campaign = useContext(CampaignContext) as CompleteCampaign;
  return campaign;
}

export function useCampaignRefetch() {
  return useContext(CampaignRefetchContext) as RefetchFunc;
}

export function useCampaignUpdate() {
  return useContext(CampaignUpdateContext) as ReturnType<
    typeof api.campaign.campaignUpdate.useMutation
  >;
}

export function useCampaignDeleteTarget() {
  return useContext(CampaignDisconnectTargetContext) as TargetDisconnector;
}

export function CampaignProvider({
  id,
  teamAccountId,
  children,
}: {
  id: string;
  teamAccountId?: string;
  children: React.ReactNode;
}) {
  const [data, setData] = useState<
    RouterOutputs["campaign"]["campaignById"] | null
  >(null);
  const { handleRefetch: campaignsRefetch } = useCampaigns();
  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = teamAccountId ?? activeTeamAccount?.id;
  // TODO: use id from route params if available
  const query = api.campaign.campaignById.useQuery(
    {
      campaignId: id,
      teamAccountId: activeTeamAccountId,
    },
    { refetchOnWindowFocus: false }
  );

  const { refetch } = query;

  const refetchOnSuccess = {
    onSuccess: async () => {
      await refetch();
      campaignsRefetch();
    },
  };

  const update = api.campaign.campaignUpdate.useMutation(refetchOnSuccess);
  const deleteTarget = api.campaign.deleteTarget.useMutation(refetchOnSuccess);

  const refetchCampaign = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const disconnectTarget = useCallback(
    (githubTargetId: string, targetContactId: string, type: string) => {
      deleteTarget.mutate({
        type,
        campaignId: id,
        teamAccountId: activeTeamAccountId,
        githubTargetId,
        targetContactId,
      });
    },
    [deleteTarget, activeTeamAccountId, data]
  );

  useEffect(() => {
    if (query.isSuccess) {
      setData(query.data);
    }
  }, [query]);

  if (!data || !update || !refetchCampaign || !disconnectTarget) {
    return null;
  }

  return (
    <CampaignContext.Provider value={data}>
      <CampaignUpdateContext.Provider value={update}>
        <CampaignRefetchContext.Provider value={refetchCampaign}>
          <CampaignDisconnectTargetContext.Provider value={disconnectTarget}>
            {children}
          </CampaignDisconnectTargetContext.Provider>
        </CampaignRefetchContext.Provider>
      </CampaignUpdateContext.Provider>
    </CampaignContext.Provider>
  );
}
