import type { TargetSearchContextType } from "./TargetSearchContextType";

const initialState: TargetSearchContextType = {
  repoTargets: [],
  userTargets: [],
  searching: false,
  removedTargets: [],
  showOrgs: true,
  showRepos: true,
  showUsers: true,
  showEmpty: false,
  hasTooManyTargets: false,
  activeTargets: false,
};
export default initialState;
