import React, { useContext, useReducer } from "react";
import TargetSearchReducer from "~/providers/TargetSearchProvider/TargetSearchReducer";
import TargetSearchContext from "./TargetSearchContext";
import InitialState from "./TargetSearchInitialState";

export const useTargetSearch = () => {
  return useContext(TargetSearchContext);
};

export function TargetSearchProvider({
  children,
  hasTooManyTargets,
}: {
  children: React.ReactNode;
  hasTooManyTargets: boolean;
}) {
  const [state, dispatch] = useReducer<typeof TargetSearchReducer>(
    TargetSearchReducer,
    { ...InitialState, hasTooManyTargets },
  );

  return (
    <TargetSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </TargetSearchContext.Provider>
  );
}
