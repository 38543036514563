import {
  ADD_REPO_TARGET,
  ADD_USER_TARGET,
  REMOVE_TARGET,
  SET_REMOVED_TARGETS,
  SET_SEARCHING,
  SET_SHOW_ORGS,
  SET_SHOW_REPOS,
  SET_FULL_ORG,
  SET_SHOW_USERS,
  SET_HAS_TOO_MANY_TARGETS,
  SET_ACTIVE_TARGETS,
  REMOVE_ALL_TARGETS,
} from "./TargetSearchActions";
import {
  type ActionOptionsWithPayload,
  type TargetSearchContextType,
} from "./TargetSearchContextType";
import { getGithubName } from "@openqlabs/utils";

const TargetSearchReducer = (
  state: TargetSearchContextType,
  action: ActionOptionsWithPayload
): TargetSearchContextType => {
  switch (action.type) {
    case ADD_REPO_TARGET: {
      const url = action.payload.tempId;
      const nameWithOwner = getGithubName(url);
      const removed = state.removedTargets.includes(url);
      const inCategoryTargets = state.repoTargets.some(
        (target) => target.tempId === url
      );
      if (removed || inCategoryTargets) {
        return state;
      }
      const currentTargets = state.repoTargets;

      return {
        ...state,
        repoTargets: [
          ...currentTargets,
          {
            tempId: nameWithOwner,
            customFields: action.payload.customFields,
          },
        ],
      };
    }
    case ADD_USER_TARGET: {
      const url = action.payload.tempId;
      const login = getGithubName(url);
      const userTargets = state.userTargets;

      const removed = state.removedTargets.includes(login);
      const inUserTargets = state.userTargets.some(
        (target) => target.tempId === login
      );
      if (removed || inUserTargets) {
        return state;
      }
      return {
        ...state,
        userTargets: [
          ...userTargets,
          {
            tempId: login,
            customFields: action.payload.customFields,
          },
        ],
      };
    }
    case REMOVE_TARGET: {
      const tempId = action.payload.metadata;
      const repoTargets = state.repoTargets.filter(
        (target) => target.tempId !== tempId
      );
      const userTargets = state.userTargets.filter(
        (target) => target.tempId !== tempId
      );
      return {
        ...state,
        repoTargets,
        userTargets,
        removedTargets: [...state.removedTargets, tempId],
      };
    }
    case SET_SEARCHING: {
      const isSearching = action.payload.metadata;
      return {
        ...state,
        searching: isSearching,
      };
    }
    case SET_REMOVED_TARGETS: {
      const removedTargets = action.payload.metadata;
      return {
        ...state,
        removedTargets,
      };
    }
    case SET_SHOW_ORGS: {
      const showOrgs = action.payload.metadata;
      return {
        ...state,
        showOrgs,
      };
    }
    case SET_SHOW_REPOS: {
      const showRepos = action.payload.metadata;
      return {
        ...state,
        showRepos,
      };
    }
    case SET_SHOW_USERS: {
      const showUsers = action.payload.metadata;
      return {
        ...state,
        showUsers,
      };
    }
    case SET_ACTIVE_TARGETS: {
      const activeTargets = action.payload.metadata;
      return {
        ...state,
        activeTargets,
      };
    }
    case SET_HAS_TOO_MANY_TARGETS: {
      return {
        ...state,
        hasTooManyTargets: action.payload.metadata,
      };
    }
    case REMOVE_ALL_TARGETS: {
      return {
        ...state,
        repoTargets: [],
        userTargets: [],
        removedTargets: [],
      };
    }
    case SET_FULL_ORG: {
      const fullOrg = action.payload.metadata;
      return {
        ...state,
        fullOrg,
      };
    }

    default:
      return state;
  }
};
export default TargetSearchReducer;
